// 
// _table.scss
// 

.table {
  th {
      font-weight: $font-weight-bold;
  }

  button {
    height: 28px;
    font-size: 10px;
    color: #ffffff;
    border-radius: 4px;
    border: none;
    padding-left: 10px;
    padding-right: 10px;

    &.detail {
      background-color: #7fd051;
    }
    &.edit {
      background-color: #555bae;
    }
		&.edit-pass {
			background-color: #fd9601;
		}
    &.delete {
      background-color: #ef5c5c;
    }
    &.disabled {
      background-color: #d1d1d8;
    }
  }
}

//Table centered
.table-centered {
  td,th {
      vertical-align: middle !important;
  }
}

.table-nowrap {
  th, td {
    white-space: nowrap;
  }
}