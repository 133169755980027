// 
// _buttons.scss
// 

button,a {
  outline: none !important;
}


// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
}

.btn-dark{
  color: $gray-200 !important;
}

.btn-outline-dark{
  &:hover{
    color: $gray-200 !important;
  }
}

.btn-group {
  height: 48px;
  // width: auto;
  width: 332px;
  box-shadow: 0 12px 36px -12px rgba(100, 24, 194, 0.12);

  .btn {
    border-radius: 4px;
    width: 170px;
  }
  
  .btn-group-active {
    background-color: #925ae0;
    color: #ffffff;
    border: none;
  }

  .btn-group-deactivate {
    background-color: #ffffff;
    // color: $bptj-primary;
    border: none;
  }
}
