//
// _layouts.scss
//

body[data-layout-size="boxed"] {
  #layout-wrapper {
    background-color: $body-bg;
    max-width: $boxed-layout-width;
    margin: 0 auto;
    box-shadow: $box-shadow;
  }

  #page-topbar {
    max-width: $boxed-layout-width;
    margin: 0 auto;
  }

  .footer {
    margin: 0 auto;
    max-width: calc(#{$boxed-layout-width} - #{$sidebar-width});
  }

  &.vertical-collpsed {
    .footer {
      max-width: calc(#{$boxed-layout-width} - #{$sidebar-collapsed-width});
    }
  }
}

// Horizontal Boxed Layout

body[data-layout="horizontal"][data-layout-size="boxed"] {
  #page-topbar,
  #layout-wrapper,
  .footer {
    max-width: 100%;
  }

  #layout-wrapper {
    box-shadow: none;
  }
  .container-fluid,
  .navbar-header {
    max-width: $boxed-layout-width;
  }
}

.page-title {
  font-size: 20px;
  font-weight: bold;
}

.horizontal-bar-chart-title {
  margin-bottom: 3px;
}

.horizontal-bar-chart-title-type {
  font-size: 12px;
  margin: auto 0;
}

.text-bold {
  font-weight: bold;
}

.horizontal-bar-chart-line {
  border: solid 1px #efe7f9;
}

.kpi-list-container {
  height: 100%;
  width: 100%;
}

.actions-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  .action-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    flex-wrap: wrap;
    height: 100;

    p {
      margin: auto 10px auto 0;
    }

    .action {
      margin: 5px 0;
    }

    .filter-pilar {
      width: 106px;
    }
    
    .filter-pilar-width {
      width: 170px;
    }

    .filter-in-change {
      width: 203px;
    }

    .filter-in-change-width {
      width: 285px;
    }

    .filter-year {
      width: 140px;
    }
  }
}

.pie-chart-container {
  .pie-chart {
    flex: 2;
  }

  .pie-chart-info {
    flex: 1;

    .total {
      margin: 0;
      font-size: 30px;
      font-weight: 900;
      line-height: 20px;
    }

    .type {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
}

.pie-chart-info {
  .total {
    margin: 0;
    font-size: 24px;
    font-weight: 900;
    line-height: 22px;
  }

  .type {
    // font-size: 18px;
    margin-bottom: 10px;
  }
}

.modal-body {
  p {
    margin: 0;
  }
}
