// 
// _card.scss
// 

.card {
  margin-bottom: $grid-gutter-width;
  box-shadow: $box-shadow;
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 16px;
  margin-bottom: 8px;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;  
}

.card-shadow {
  box-shadow: 0 12px 36px -12px rgba(100, 24, 194, 0.12);
  border-radius: 6px;
  background-color: #ffffff;
}

.kpi-list-card {
  height: 100%;
  width: 100%;
  display: flex;

  .kpi-percentage {
    display: flex;
    align-items: center;

    div {
      margin: auto;
      height: 48px;
      width: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;

      .block-unit {
        display: block;
        padding-top: 9px;
        p {
          color: #ffffff;
          text-align: center;
          line-height: 15px;
        }
      }

      p {
        margin: 0;
        color: #ffffff;
        text-align: center;
      }
    }
  }

  .kpi-name {
    flex: 1;
    margin: auto;
    padding: 0 0 0 10px;
    p {
      margin: 0;
    }
  }
}

.kpi-tooltip {
  width: 280px;
}

.year-tooltip {
  width: 200px;
}
