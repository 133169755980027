.iku-form {
  button {
    height: 42px;
    border-radius: 6px;
    border: none;
    color: #ffffff;

    &.bg-red {
      background-color: #ef5c5c;
    }

    &.bg-purple {
      background-color: #555bae;
    }

    &.bg-green {
      background-color: #7fd051;
    }
  }

  input {
    height: 42px;
    border-radius: 6px;
    border-width: 0.1px;
    padding-left: 12px;
    padding-right: 70;
  }

  .description {
    font-size: 12px;
  }

  .unit {
    top: 10px;
    right: 30px;
  }
}

.form {
  button {
    height: 42px;
    border-radius: 6px;
    border: none;
    color: #ffffff;
    width: 100%;

    &.bg-red {
      background-color: #ef5c5c;
    }

    &.bg-purple {
      background-color: #555bae;
    }

    &.bg-darkblue {
      background-color: #242393;
    }

    &.bg-green {
      background-color: #7fd051;
    }

    &.bg-grey {
      background-color: #ccd1d9;
    }
  }
}
