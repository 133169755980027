.speedo-container {
  height: 100%;
  display: flex;
  
  .percentage {
    background-color: #242393;
    height: 55px;
    width: 55px;
    border-radius: 6px;
    margin-top: 20px;
    margin-left: 20px;
    position: absolute;

    p {
      text-align: center;
      line-height: 55px;
      color: #ffffff;
      font-size: 18px;
      font-weight: 600;
    }
  }
  
  .speedo {
    width: 100%;
    padding-top: 50%; /* Aspect Ratio */
    position: relative;
    align-self: center;

    .aspect-ratio {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      padding: 0 20px;

      .speedo-chart {
        width: 100%;
        height: 100%;
      }
      
      p {
        text-align: center;
      }
    }
  }
}
